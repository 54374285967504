import React, { useEffect, useState } from "react";
import {
  menuButton,
  menuLines,
  navOpen,
  header,
  logo,
} from "./header.module.scss";
import { Link } from "gatsby";
import Navigation from "../navigation/navigation";
import logoImage from "../../assets/logos/logo.svg";

const Header = ({ navOpenChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.documentElement.style.scrollBehavior = "auto";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.documentElement.style.scrollBehavior = "";
    setIsOpen(false);
    if (navOpenChange) {
      navOpenChange(false);
    }
  };

  const open = () => {
    const scrollY = window.scrollY;
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    setIsOpen(true);
    if (navOpenChange) {
      navOpenChange(true);
    }
  };

  const toggle = () => {
    isOpen ? close() : open();
  };

  return (
    <header id="header" className={header}>
      <Link to="/" className={logo} onClick={close}>
        <img src={logoImage} alt="Csite Logo" />
      </Link>
      <Navigation isOpen={isOpen} close={close} />
      <button
        onClick={() => toggle()}
        className={`${menuButton} ${isOpen ? navOpen : ""}`}
        aria-label="Open Nav"
      >
        <div className={menuLines}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </button>
    </header>
  );
};

export default Header;
