import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import {
  activeLink,
  open,
  appearance,
  themeSwitch,
  slider,
  sliderBall,
  nav,
} from "./navigation.module.scss";
import darkModeIcon from "../../assets/icons/dark-mode.svg";
import lightModeIcon from "../../assets/icons/light-mode.svg";
import { useState } from "react";
import { useEffect } from "react";

const Navigation = ({ isOpen, close }) => {
  const links = [
    { to: "/kontakt/", text: "Kontakt" },
    { to: "/impressum/", text: "Impressum" },
    { to: "/datenschutz/", text: "Datenschutz" },
  ];
  const location = useLocation();
  const [darkModeActive, setDarkModeActive] = useState(false);

  const isActive = (link) => {
    if (location.hash === "") {
      return link === "/";
    }
    return link.includes(location.hash);
  };

  const onNavClick = () => {
    close();
  };

  const onAppearenceClick = (event) => {
    event.stopPropagation();
  };

  const changeAppearence = (theme) => {
    if (!theme) {
      return;
    }
    const classList = document.documentElement.classList;
    setDarkModeActive(theme === "dark");
    classList.remove("dark");
    classList.remove("light");
    classList.add(theme);
    localStorage.setItem("csite-theme", theme);
  };

  const onAppearenceChange = (event) => {
    changeAppearence(event.target.checked ? "dark" : "light");
  };

  useEffect(() => {
    let theme = localStorage.getItem("csite-theme") ?? null;
    if (window.matchMedia && !theme) {
      theme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    }
    if (theme) {
      changeAppearence(theme);
    }
  }, []);

  return (
    <nav
      className={`${nav} ${isOpen ? `${open}` : ""}`}
      onClick={() => onNavClick()}
      onKeyDown={() => close()}
    >
      {links.map((link) => (
        <Link
          key={link.to}
          to={link.to}
          className={isActive(link.to) ? activeLink : ""}
        >
          {link.text}
        </Link>
      ))}
      <div className={appearance}>
        <p>Design: {darkModeActive ? "Dunkel" : "Hell"}</p>
        <label
          className={themeSwitch}
          htmlFor="checkbox"
          onClick={onAppearenceClick}
        >
          <input
            type="checkbox"
            id="checkbox"
            onChange={onAppearenceChange}
            checked={darkModeActive ?? false}
          />
          <div className={slider}>
            <div className={sliderBall}>
              <img
                src={darkModeActive ? darkModeIcon : lightModeIcon}
                alt="Theme switch icon"
              />
            </div>
          </div>
        </label>
      </div>
    </nav>
  );
};

export default Navigation;
