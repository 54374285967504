// extracted by mini-css-extract-plugin
export var bgLogo = "parallax-logos-module--bgLogo--9993c";
export var dBlock = "parallax-logos-module--d-block--d1451";
export var dFlex = "parallax-logos-module--d-flex--85d3a";
export var dInline = "parallax-logos-module--d-inline--7ce95";
export var dLgBlock = "parallax-logos-module--d-lg-block--18a5b";
export var dLgFlex = "parallax-logos-module--d-lg-flex--41577";
export var dLgInline = "parallax-logos-module--d-lg-inline--a8ed1";
export var dLgNone = "parallax-logos-module--d-lg-none--120f6";
export var dMdBlock = "parallax-logos-module--d-md-block--6b8c9";
export var dMdFlex = "parallax-logos-module--d-md-flex--1ea61";
export var dMdNone = "parallax-logos-module--d-md-none--55024";
export var dNone = "parallax-logos-module--d-none--de6e7";
export var parallax = "parallax-logos-module--parallax--f16ed";