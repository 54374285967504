import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import { content } from "./layout.module.scss";
import { createContext } from "react";
import Seo from "../seo/seo";
import ParallaxLogos from "../../animations/parallax-logos/parallax-logos";

const LocaleContext = createContext();

const Layout = ({ children, pageContext: { locale } }) => {
  return (
    <LocaleContext.Provider value={{ locale }}>
      <Seo lang={locale} />
      <Header />
      <main className={content}>{children}</main>
      <ParallaxLogos />
      <Footer />
    </LocaleContext.Provider>
  );
};

export { Layout, LocaleContext };
