import { footer, copyRight } from "./footer.module.scss";
import { Link } from "gatsby";
import React from "react";

const year = new Date().getFullYear();
const Footer = () => {
  return (
    <footer id="footer" className={footer}>
      <span className={copyRight}>© Csite {year}</span>
      <Link to="/impressum/">Impressum</Link>
      <Link to="/datenschutz/">Datenschutz</Link>
    </footer>
  );
};

export default Footer;
