import React, { useEffect, useState } from "react";
import logoImage from "../../assets/logos/logo.svg";
import { bgLogo, parallax } from "./parallax-logos.module.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const ParallaxLogos = () => {
  const minImages = 4;
  const [images, setImages] = useState(minImages);
  useEffect(() => {
    const scrollHeight = document.body.offsetHeight;
    const windowHeight = window.innerHeight;
    // Calculates how many images should be added
    setImages(Math.max(Math.floor(scrollHeight / windowHeight), minImages));
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("." + parallax, {
      scrollTrigger: {
        scrub: 0,
        endTrigger: "#footer",
      },
      ease: "none",
      translateY: `-${scrollHeight / 3}`,
    });
  }, [images]);
  return (
    <div className={parallax}>
      {[...Array(images)].map((_, i) => (
        <img
          key={`image-${i}`}
          src={logoImage}
          className={bgLogo}
          alt="Csite Logo Background"
        />
      ))}
    </div>
  );
};

export default ParallaxLogos;
