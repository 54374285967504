import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { defaultLocale, locales } from "../../../config/i18n";

const siteMetadata = {
  de: {
    title: "Csite | Websites aus Österreich",
    description:
      "Website, App, Design, SEO, CMS ... interessiert dich nicht? Wir erledigen das!",
    author: "https://www.csite.at/",
    siteUrl: "https://www.csite.at/",
    image: "https://csite.fra1.cdn.digitaloceanspaces.com/csite-og-image.png",
  },
  en: {
    title: "Csite | Websites build in Austria",
    description:
      "Website, App, Design, SEO, CMS ... not interested? We'll do it!",
    author: "https://www.csite.at/",
    siteUrl: "https://www.csite.at/",
    image: "https://csite.fra1.cdn.digitaloceanspaces.com/csite-og-image.png",
  },
};

const Seo = ({ description, lang, meta, image: metaImage, title }) => {
  const metadata = siteMetadata[lang];
  const metaDescription = description || metadata.description;
  const location = useLocation();

  if (metaImage) {
    metaImage = {
      src: metaImage.src,
      width: metaImage.width || 753,
      height: metaImage.height || 618,
    };
  } else {
    metaImage = {
      src: metadata.image,
      width: 753,
      height: 618,
    };
  }
  const metaTitle = (title ? `${title} | ` : "") + metadata.title;
  const getLocaleHref = (lang) => {
    let pathname = location.pathname;
    // Remove all locale ptaths
    locales.forEach((l) => (pathname = pathname.replace(`/${l}/`, "/")));
    if (defaultLocale === lang) {
      return `${location.origin}${pathname}`;
    }
    return `${location.origin}/${lang}${pathname}`;
  };
  const alternateLinks = [
    {
      rel: "alternate",
      hrefLang: "x-default",
      href: getLocaleHref(defaultLocale),
    },
    ...locales.map((locale) => ({
      rel: "alternate",
      hrefLang: locale,
      href: getLocaleHref(locale),
    })),
  ];

  // TODO: !!!!!!!!USE GATSBY HEAD API INSTEAD!!!!!!

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      link={alternateLinks}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: metadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: metaImage.src,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ],
        )
        .concat(meta)}
    />
  );
};
Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
};
Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  pathname: PropTypes.string,
};
export default Seo;
